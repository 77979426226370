<ion-app>
	<ion-split-pane contentId="main-content" class="safe-area" when="false">
		<ion-menu contentId="main-content" type="overlay">
			<ion-content>
				<div class="header-item" style="width:60%;display: block;margin:10px auto;">
					<img src="assets/logo_atp.webp" style="width:100%" />
				</div>
				<p *ngIf="names!=''" style="text-align: center;font-size:13px;text-transform: capitalize;">Bonjour, {{nameSubscriber}}</p>
				<div *ngFor="let p of appPages; let i = index">
					<ion-menu-toogle *ngIf="p.url">
						<ion-item lines="none" routerDirection="root" [routerLink]="p.url" *ngIf="p.url!='/link'" routerLinkActive="active" (click)="menu.close()">
							<ion-icon slot="start" [name]="p.icon" color="greys" size="large"></ion-icon>
							<ion-label>{{ p.title }}</ion-label>
						</ion-item>
						<ion-item lines="none" routerDirection="root" *ngIf="p.url=='/link'" routerLinkActive="active" (click)="menu.close();goTo();" [class.selected]="selectedIndex == i">
							<ion-icon slot="start" [name]="p.icon" color="greys" size="large"></ion-icon>
							<ion-label>{{ p.title }}</ion-label>
						</ion-item>
					</ion-menu-toogle>
				</div>

				<ion-menu-toogle *ngIf="showBtnDisconnect==1">
					<ion-item lines="none" (click)="logout()">
						<ion-icon slot="start" src="assets/logout.svg" color="greys" size="large"></ion-icon>
						<ion-label>Se déconnecter</ion-label>
					</ion-item>
				</ion-menu-toogle>
			</ion-content>
			<ion-footer>
				<ion-item lines="none">
					<ion-label style="font-size:12px;text-align: left;">version : {{versionNumber}}&nbsp;</ion-label>
				</ion-item>
			</ion-footer>
		</ion-menu>
		<ion-router-outlet id="main-content"></ion-router-outlet>
	</ion-split-pane>
</ion-app>