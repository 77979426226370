import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, ExtraOptions } from '@angular/router';
import { AuthGuard } from './auth.guard'; // ATP Nancy
import { Auth2Guard } from './auth2.guard'; // Inter CEA

const routes: Routes = [

  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) // Check if we should show the introduction or forward to inside
  },
  {
    path: '',
    redirectTo: '/home-page-cards',
    pathMatch: 'full'
  },
  /*{
    path: 'onboarding-two',
    loadChildren: () => import('./pages/onboarding-two/onboarding-two.module').then(m => m.OnboardingTwoPageModule)
  },*/
  {
    path: 'modal-confirmation',
    loadChildren: () => import('./pages/modal-confirmation/modal-confirmation.module').then(m => m.ModalConfirmationPageModule)
  },
  {
    path: 'mentions',
    loadChildren: () => import('./pages/mentions/mentions.module').then(m => m.MentionsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
    canActivate: [Auth2Guard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [Auth2Guard]
  },
  {
    path: 'tabs-atp',
    loadChildren: () => import('./pages/tabs-atp/tabs-atp.module').then(m => m.TabsAtpPageModule),
    canActivate: [AuthGuard]
  },
  /*{
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full'
  },  */

  {
    path: 'home-page-cards',
    loadChildren: () => import('./pages/home-page-cards/home-page-cards.module').then(m => m.HomePageCardsPageModule)
  },

  {
    path: 'identity',
    loadChildren: () => import('./pages/identity/identity.module').then(m => m.IdentityPageModule),
    canActivate: [Auth2Guard]
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
    canActivate: [Auth2Guard]
  },
  {
    path: 'slips',
    loadChildren: () => import('./pages/slips/slips.module').then(m => m.SlipsPageModule),
    canActivate: [Auth2Guard]
  },
  {
    path: 'card',
    loadChildren: () => import('./pages/card/card.module').then(m => m.CardPageModule)
  },
  {
    path: 'etickets',
    loadChildren: () => import('./pages/etickets/etickets.module').then(m => m.EticketsPageModule),
    canActivate: [Auth2Guard]
  },
  {
    path: 'take-photo',
    loadChildren: () => import('./pages/take-photo/take-photo.module').then(m => m.TakePhotoPageModule),
    canActivate: [Auth2Guard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./pages/product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
  },

  {
    path: 'cart-modal',
    loadChildren: () => import('./pages/cart-modal/cart-modal.module').then(m => m.CartModalPageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('./pages/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartPageModule)
  },
  {
    path: 'addresses',
    loadChildren: () => import('./pages/addresses/addresses.module').then(m => m.AddressesPageModule),
    canActivate: [Auth2Guard]
  },

  {
    path: 'add-address',
    loadChildren: () => import('./pages/add-address/add-address.module').then(m => m.AddAddressPageModule)
  },
  {
    path: 'my-address',
    loadChildren: () => import('./pages/my-address/my-address.module').then(m => m.MyAddressPageModule),
    canActivate: [Auth2Guard]
  },
  {
    path: 'sub-categories',
    loadChildren: () => import('./pages/sub-categories/sub-categories.module').then(m => m.SubCategoriesPageModule)
  },
  {
    path: 'mondial-modal',
    loadChildren: () => import('./pages/mondial-modal/mondial-modal.module').then(m => m.MondialModalPageModule)
  },

  {
    path: 'location',
    loadChildren: () => import('./pages/location/location.module').then(m => m.LocationPageModule)
  },
  {
    path: 'confirmation',
    loadChildren: () => import('./pages/confirmation/confirmation.module').then(m => m.ConfirmationPageModule)
  },
  {
    path: 'annulling',
    loadChildren: () => import('./pages/annulling/annulling.module').then(m => m.AnnullingPageModule)
  },
  {
    path: 'cgv',
    loadChildren: () => import('./pages/cgv/cgv.module').then(m => m.CgvPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  {
    path: 'sub-sub-categories',
    loadChildren: () => import('./pages/sub-sub-categories/sub-sub-categories.module').then(m => m.SubSubCategoriesPageModule)
  },
  {
    path: 'modal-partner',
    loadChildren: () => import('./pages/modal-partner/modal-partner.module').then(m => m.ModalPartnerPageModule)
  },

  {
    path: 'home-atp',
    loadChildren: () => import('./pages/home-atp/home-atp.module').then(m => m.HomeAtpPageModule)
  },
  {
    path: 'settings-atp',
    loadChildren: () => import('./pages/settings-atp/settings-atp.module').then(m => m.SettingsAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'card-atp',
    loadChildren: () => import('./pages/card-atp/card-atp.module').then(m => m.CardAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login-atp',
    loadChildren: () => import('./pages/login-atp/login-atp.module').then(m => m.LoginAtpPageModule)
  },
  {
    path: 'identity-atp',
    loadChildren: () => import('./pages/identity-atp/identity-atp.module').then(m => m.IdentityAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'addresses-atp',
    loadChildren: () => import('./pages/addresses-atp/addresses-atp.module').then(m => m.AddressesAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'orders-atp',
    loadChildren: () => import('./pages/orders-atp/orders-atp.module').then(m => m.OrdersAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mentions-atp',
    loadChildren: () => import('./pages/mentions-atp/mentions-atp.module').then(m => m.MentionsAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'add-address-atp',
    loadChildren: () => import('./pages/add-address-atp/add-address-atp.module').then(m => m.AddAddressAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-address-atp',
    loadChildren: () => import('./pages/my-address-atp/my-address-atp.module').then(m => m.MyAddressAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'offers-atp',
    loadChildren: () => import('./pages/offers-atp/offers-atp.module').then(m => m.OffersAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications-atp',
    loadChildren: () => import('./pages/notifications-atp/notifications-atp.module').then(m => m.NotificationsAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'contact-atp',
    loadChildren: () => import('./pages/contact-atp/contact-atp.module').then(m => m.ContactAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-map',
    loadChildren: () => import('./pages/modal-map/modal-map.module').then(m => m.ModalMapPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'products-atp',
    loadChildren: () => import('./pages/products-atp/products-atp.module').then(m => m.ProductsAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'product-detail-atp',
    loadChildren: () => import('./pages/product-detail-atp/product-detail-atp.module').then(m => m.ProductDetailAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cart-modal-atp',
    loadChildren: () => import('./pages/cart-modal-atp/cart-modal-atp.module').then(m => m.CartModalAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'shopping-cart-atp',
    loadChildren: () => import('./pages/shopping-cart-atp/shopping-cart-atp.module').then(m => m.ShoppingCartAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-stripe',
    loadChildren: () => import('./pages/modal-stripe/modal-stripe.module').then(m => m.ModalStripePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'cgv-atp',
    loadChildren: () => import('./pages/cgv-atp/cgv-atp.module').then(m => m.CgvAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'calendar-atp',
    loadChildren: () => import('./pages/calendar-atp/calendar-atp.module').then(m => m.CalendarAtpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-article',
    loadChildren: () => import('./pages/modal-article/modal-article.module').then(m => m.ModalArticlePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal-calendar',
    loadChildren: () => import('./pages/modal-calendar/modal-calendar.module').then(m => m.ModalCalendarPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'offer-map-atp',
    loadChildren: () => import('./pages/offer-map-atp/offer-map-atp.module').then(m => m.OfferMapAtpPageModule),
    canActivate: [AuthGuard]
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
