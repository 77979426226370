import { Component, OnInit } from '@angular/core';
import { Platform, MenuController, NavController } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { ServerService } from './service/server.service';
import { AtpserverService } from './service/atpserver.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import OneSignal from 'onesignal-cordova-plugin';
import { App } from '@capacitor/app';
import { ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [];

  myNotificationAtp = this.atpserver.getNotification();
  myConnected = this.server.getConnected();
  myConnectedAtp = this.atpserver.getConnected();
  myAtp = this.atpserver.getAtps();


  showBtnDisconnect: any;
  names: any;
  versionNumber: any;
  private update = new BehaviorSubject<boolean>(false);
  items: any;
  itemsAtp: any;
  subscriber_username: string;
  nameSubscriber: any;

  constructor(
    private platform: Platform,
    public menu: MenuController,
    private router: Router,
    public server: ServerService,
    public atpserver: AtpserverService,
    private navController: NavController,
    private cd: ChangeDetectorRef,
  ) {

    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      StatusBar.setStyle({ style: Style.Default });
      StatusBar.setOverlaysWebView({ overlay: true });
      SplashScreen.show({
        showDuration: 3000,
        autoHide: true,
      });

      if (!localStorage.getItem('notification_atp')) {
        localStorage.setItem('notification_atp', JSON.stringify(true));
      }
      this.atpserver.getNotificationObservable().subscribe(notifyId => {
        this.myNotificationAtp = notifyId;
        console.log('Notify comp', this.myNotificationAtp);

        this.subPush(this.myNotificationAtp);

      });
      //this.getPlayerId(false);
    });
  }

  ngOnInit() {

    console.log('appages', this.appPages)

    this.navController.back();
    this.retrieveUpdate();
    // this.loadDatas();

  }

  async retrieveUpdate() {
    console.log('Get Update app.components')
    const info = await App.getInfo();
    let version = info.version;

    this.atpserver.getUpdate().subscribe((data: any) => {
      if (data) {
        console.log('load an')
        let android = data.android;
        console.log('android', android);
        console.log('vers android', version);
        if (version != android) {
          //this.presentAlert()
          localStorage.setItem('update_atp', JSON.stringify(true))
          console.log('update true app')
        } else {
          localStorage.setItem('update_atp', JSON.stringify(false))
          console.log('update false app')
        }

      } else {
        console.log('no datas')
      }
    });
  }

  subPush(e: string) {

    OneSignal.setAppId('f12df84f-7e8f-4ccd-bcf9-7fc0f0ec9257');
    OneSignal.setNotificationOpenedHandler((jsonData) => {
      this.router.navigateByUrl('/tabs-atp/notification', { replaceUrl: true });
    });
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {

    });
    console.log('onesignal tag notify', e);
    OneSignal.sendTags({ notification: e });

    OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
      console.log('Notification permission accepted:', accepted);

      this.getPlayerId(true);
    });

  }

  getPlayerId(e: boolean) {
    if (!e) {
      OneSignal.setAppId('f12df84f-7e8f-4ccd-bcf9-7fc0f0ec9257');

      OneSignal.setNotificationOpenedHandler((jsonData) => {
        this.router.navigateByUrl('/tabs-atp/notification', { replaceUrl: true });
      });
      OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {

      });
    }

    OneSignal.getDeviceState((deviceState) => {
      const playerId = deviceState.userId; // Player ID
      console.log('Player ID:', playerId);
      const storedPlayerId = localStorage.getItem('playerId');

      if (!storedPlayerId) {
        localStorage.setItem('playerId', playerId);
      }

    });


  }

  async loadDatas() {
    this.appPages = [
      {
        title: 'Les ATP',
        icon: 'cube-outline',
        url: '/home-page-cards',
        open: false
      },
      {
        title: localStorage.getItem('name_atp'),
        icon: 'home-outline',
        url: '/tabs-atp/home',
        open: false
      },
      {
        title: 'Mon compte',
        icon: 'person-circle-outline',
        url: '/settings-atp',
        open: false
      },
      {
        title: 'Inter C.E.A',
        icon: 'exit-outline',
        url: '/tabs/home',
        open: false
      },
      {
        title: 'Se déconnecter',
        icon: 'power',
        url: '/home-page-cards',
        open: false
      }
    ];

    this.nameSubscriber = this.atpserver.getName();

  }
}
