import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AtpserverService {

  atpurl = environment.atpUrl;
  url = environment.Url;

  public myNotificationAtp = new BehaviorSubject<any>(null);
  public myConnectedAtp = new BehaviorSubject<any>(null);
  public myAtp = new BehaviorSubject<any>(null);
  public nameSubscriber = new BehaviorSubject<any>(null);
  public myStripeAtp = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) { }

  getNotification() {
    this.myNotificationAtp.next(localStorage.getItem('notification_atp'));
    return this.myNotificationAtp.getValue();
  }

  getAtp() {
    return this.http.get(this.atpurl + 'code/getAtp.php')
      .pipe(map(results => results));
  }

  //on charge état connexion
  getConnected() {
    this.myConnectedAtp.next(localStorage.getItem('subscriber_identified'));
    return this.myConnectedAtp.getValue();
  }

  getConnectedObservable(): Observable<any> {
    return this.myConnectedAtp.asObservable();
  }

  getName() {
    this.nameSubscriber.next(localStorage.getItem('subscriber_username'));
    return this.nameSubscriber.getValue();
  }

  getNameObservable(): Observable<any> {
    return this.nameSubscriber.asObservable();
  }

  getNotificationObservable(): Observable<any> {
    return this.myNotificationAtp.asObservable();
  }

  getAtps() {
    this.myAtp.next(localStorage.getItem('my_atp'));
    return this.myAtp.getValue();
  }

  getAtpObservable(): Observable<any> {
    return this.myAtp.asObservable();
  }


  getStripe() {
    this.myStripeAtp.next(localStorage.getItem('public_key'));
    return this.myStripeAtp.getValue();
  }

  getStripeObservable(): Observable<any> {
    return this.myStripeAtp.asObservable();
  }


  login(email: any, password: any) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
    formData.append('id_atp', localStorage.getItem('id_atp'));

    return this.http.post(`${this.atpurl}code/loginFormAtp.php`, formData);
  }

  updatePlayerId(playerId: any) {
    const formData = new FormData();
    formData.append('playerId', playerId);
    formData.append('id_atp', localStorage.getItem('id_atp'));
    formData.append('id_subscriber', localStorage.getItem('subscriber_id'));
    return this.http.post(`${this.atpurl}code/updatePlayerId.php`, formData);
  }

  retrieve(email2: any) {
    const formData = new FormData();
    formData.append('email', email2);

    return this.http.post(`${this.atpurl}code/retrievePasswordAtp.php`, formData);
  }

  customerDatas(subscriber_id: number, id_member: number) {
    return this.http.get(this.atpurl + 'code/customerDatasAtp.php?subscriber_id=' + subscriber_id + '&id_member=' + id_member)
      .pipe(map(results => results));
  }

  validityDatas(subscriber_id: number) {
    return this.http.get(this.atpurl + 'code/validityDatasAtp.php?subscriber_id=' + subscriber_id)
      .pipe(map(results => results));
  }

  updateCustomer(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/updateCustomerAtp.php`, formData);
  }

  sendMessage(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/sendMessageAtp.php`, formData);
  }

  addressDatas(subscriber_id: any) {
    return this.http.get(this.atpurl + 'code/addressDatasAtp.php?subscriber_id=' + subscriber_id)
      .pipe(map(results => results));
  }

  myAddressDatas(subscriber_id: any, id_address: any) {
    return this.http.get(this.atpurl + 'code/myAddressDatasAtp.php?subscriber_id=' + subscriber_id + '&id_address=' + id_address)
      .pipe(map(results => results));
  }

  addAddress(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/addAddressAtp.php`, formData);
  }

  updateAddress(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/updateAddressAtp.php`, formData);
  }

  deleteAddress(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/deleteAddress.php`, formData);
  }

  ordersDatas(subscriber_id: number) {
    return this.http.get(this.atpurl + 'code/ordersDatasAtp.php?subscriber_id=' + subscriber_id)
      .pipe(map(results => results));
  }

  cardDatas(subscriber_id: number, id_atp: number, id_member: number) {
    return this.http.get(this.atpurl + 'code/cardDatasAtp.php?subscriber_id=' + subscriber_id + '&id_atp=' + id_atp + '&id_member=' + id_member)
      .pipe(map(results => results));
  }


  register(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/addSubscriberAtp.php`, formData);
  }

  subscribeCalendar(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/subscribeCalendarAtp.php`, formData);
  }

  subscribeWaitingCalendar(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/subscribeWaitingCalendarAtp.php`, formData);
  }

  unsubscribeCalendar(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/unsubscribeCalendarAtp.php`, formData);
  }

  offersList() {
    return this.http.get(this.atpurl + 'code/offersListAtp.php?id_atp=' + localStorage.getItem('id_atp'))
      .pipe(map(results => results));
  }

  offerSearch(query: any) {
    const formData = new FormData();
    formData.append('datas', query);
    return this.http.post(`${this.atpurl}code/offerSearchAtp.php`, formData)
      .pipe(map(results => results));
  }

  statisticOffer(query: any) {
    const formData = new FormData();
    formData.append('datas', query);
    return this.http.post(`${this.atpurl}code/statisticsOfferAtp.php`, formData)
      .pipe(map(results => results));
  }

  statisticMap(query: any) {
    const formData = new FormData();
    formData.append('datas', query);
    return this.http.post(`${this.atpurl}code/statisticsMapAtp.php`, formData)
      .pipe(map(results => results));
  }

  getUpdate() {
    return this.http.get(this.atpurl + 'code/getUpdateAtp.php')
      .pipe(map(results => results));
  }

  productsList() {
    return this.http.get(this.atpurl + 'code/productsListAtp.php?id_atp=' + localStorage.getItem('id_atp'))
      .pipe(map(results => results));
  }

  productDetail(id_product: number) {
    return this.http.get(this.atpurl + 'code/productDetailAtp.php?id_product=' + id_product)
      .pipe(map(results => results));
  }

  verifQty(query: any) {
    const formData = new FormData();
    formData.append('datas', query);
    return this.http.post(`${this.atpurl}code/verifQtyAtp.php`, formData)
      .pipe(map(results => results));
  }

  addWebserviceCartCb(datas: any) {
    const formData = new FormData();
    formData.append('datas', datas);
    return this.http.post(`${this.atpurl}code/addOrderCbAtp.php`, formData);
  }

  getCarriers() {
    return this.http.get(this.atpurl + 'code/getCarriersAtp.php?id_atp=' + localStorage.getItem('id_atp'));
  }

  getArticles() {
    return this.http.get(this.atpurl + 'code/articlesAtp.php?id_atp=' + localStorage.getItem('id_atp'))
      .pipe(map(results => results));
  }

  getPublicite() {
    return this.http.get(this.atpurl + 'code/pubAtp.php?id_atp=' + localStorage.getItem('id_atp'))
      .pipe(map(results => results));
  }

  notificationsDatas() {
    return this.http.get(this.atpurl + 'code/notificationsDatasAtp.php?id_atp=' + localStorage.getItem('id_atp'))
      .pipe(map(results => results));
  }

  calendarList() {
    const id_atp = localStorage.getItem('id_atp');
    const subscriber_id = localStorage.getItem('subscriber_id');
    const url = `${this.atpurl}code/calendarListAtp.php?id_atp=${id_atp}&id_subscriber=${subscriber_id}`;

    return this.http.get(url).pipe(map(results => results));
  }


  calendarSearchList(query: any) {
    const formData = new FormData();
    formData.append('datas', query);
    return this.http.post(`${this.atpurl}code/calendarSearchListAtp.php`, formData)
      .pipe(map(results => results));
  }

}
