import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { AtpserverService } from './service/atpserver.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private atpServer: AtpserverService, private router: Router) { }

  canActivate(): boolean {
    const encryptedToken = localStorage.getItem('subscriber_identified');

    if (encryptedToken) {
      try {
        // Déchiffrer le token
        const decryptedToken = CryptoJS.AES.decrypt(encryptedToken, '!atp54#').toString(CryptoJS.enc.Utf8);

        // Vérifier si le token est valide (par exemple, non vide et correspond à un email attendu)
        if (decryptedToken) {
          return true; // Autoriser l'accès à la page
        } else {
          this.router.navigate(['/login-atp'], { replaceUrl: true });
          return false; // Bloquer l'accès en cas de déchiffrement invalide
        }
      } catch (error) {
        // En cas d'erreur de déchiffrement, rediriger vers la page de connexion
        console.error('Erreur de déchiffrement du token', error);
        this.router.navigate(['/login-atp'], { replaceUrl: true });
        return false;
      }
    } else {
      this.router.navigate(['/login-atp'], { replaceUrl: true });
      return false; // Bloquer l'accès si le token n'est pas présent
    }
  }
}