import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { CartModalPageModule } from './pages/cart-modal/cart-modal.module';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { LocationAccuracy } from '@awesome-cordova-plugins/location-accuracy/ngx';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from '../environments/environment';


@NgModule({
    declarations: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        LazyLoadImageModule,
        CartModalPageModule,
        NgxStripeModule.forRoot(),

    ],
    providers: [
        InAppBrowser,
        FileTransfer,
        File,
        FileOpener,
        CallNumber,
        OneSignal,
        NativeGeocoder,
        Geolocation,
        AndroidPermissions,
        LocationAccuracy,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy, },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
