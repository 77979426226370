import { Product, CartService } from './../../services/cart.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import { ServerService } from '../../service/server.service';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.page.html',
  styleUrls: ['./cart-modal.page.scss'],
})


export class CartModalPage implements OnInit {

  cartEbillet: BehaviorSubject<boolean>;
  cartNormal: BehaviorSubject<boolean>;

  cart: Product[] = [];

  constructor(
    public server: ServerService,
    private cartService: CartService,
    private modalCtrl: ModalController,
    public nav: NavController,
  ) { }

  ngOnInit() {
    this.cart = this.cartService.getCart();
  }

  decreaseCartItem(product: any) {
    this.cartService.decreaseProduct(product);
  }

  increaseCartItem(product: any) {
    this.cartService.addProduct(product);
  }

  removeCartItem(product: any) {
    this.cartService.removeProduct(product);
  }

  getTotal() {
    return this.cart.reduce((i, j) => i + j.price * j.amount, 0);
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async checkout() {
    this.close();
    this.nav.navigateForward('/shopping-cart');
  }
}