import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { catchError, tap } from 'rxjs/operators';
/*

public class FileProvider extends androidx.core.content.FileProvider  {
}

*/

export class Menu {
  title: string;
  url: string;
  icon:string;
}
export interface Product {
  id: number;
  category: string;
  title: string;
  image: string;
  short_desc: string;
  price: number;
  discount:number;
  qty:number;
  qtys:[];
  amount?: number;
}
export interface Customer {
  email: any;
  phone: any;
  zip: number;
  adresse:any;

  ville:string;
}

export interface ApiImage {
  _id: string;
  name: string;
  createdAt: Date;
  url: string;
  img: string;
}

@Injectable({
  providedIn: 'root'
})
export class ServerService {


  url = environment.Url;
  interval:any;

 public myNotification = new BehaviorSubject<any>(null);
 public myConnected = new BehaviorSubject<any>(null);
 
  constructor(private http: HttpClient) { }

  //on charge état notification
  getNotification() {
    this.myNotification.next(localStorage.getItem('notification'));
    return this.myNotification.getValue();
  }

  //on charge état connexion
  getConnected() {
    this.myConnected.next(localStorage.getItem('identified'));

    return this.myConnected.getValue();
  }

  getNotificationObservable(): Observable<any> {
    return  this.myNotification.asObservable();
  }

  getConnectedObservable(): Observable<any> {
    return  this.myConnected.asObservable();
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }



  login(email:any, password:any){
    const formData = new FormData();
    formData.append('email', email);  
    formData.append('password', password);

    return this.http.post(`${this.url}code/loginForm.php`, formData);
  }

  retrieve(email2:any){
    const formData = new FormData();
    formData.append('email', email2);  

    return this.http.post(`${this.url}code/retrievePassword.php`, formData);
  }


  customerDatas(id_customer:number) {
    return this.http.get(this.url + 'code/customerDatas.php?id_customer='+id_customer)
      .pipe(map(results => results));
  }

  validityDatas(id_customer:number) {
    return this.http.get(this.url + 'code/validityDatas.php?id_customer='+id_customer)
      .pipe(map(results => results));
  }

  updateCustomer(datas:any){
    const formData = new FormData();
    formData.append('datas', datas);  

    return this.http.post(`${this.url}code/updateCustomer.php`, formData);
  }

  ordersDatas(id_customer:number) {
    return this.http.get(this.url + 'code/ordersDatas.php?id_customer='+id_customer)
      .pipe(map(results => results));
  }

  getCategories() {
    return this.http.get(this.url + 'code/getCategories.php')
      .pipe(map(results => results));
  }

  notificationsDatas() {
    return this.http.get(this.url + 'code/notificationsDatas.php')
      .pipe(map(results => results));
  }

  slipsDatas(id_customer:number) {
    return this.http.get(this.url + 'code/slipsDatas.php?id_customer='+id_customer)
      .pipe(map(results => results));
  }

  ticketsDatas(id_customer:number) {
    return this.http.get(this.url + 'code/eticketsDatas.php?id_customer='+id_customer+'&old=1')
      .pipe(map(results => results));
  }

  cardDatas(id_customer:number) {
    return this.http.get(this.url + 'code/cardDatas.php?id_customer='+id_customer)
      .pipe(map(results => results));
  }
  
  productsList(id_category:number) {
    return this.http.get(this.url + 'code/productsList.php?id_category='+id_category)
      .pipe(map(results => results));
  }

  categoriesSubList(id_category:number) {
    return this.http.get(this.url + 'code/categoriesSubList.php?id_category='+id_category)
      .pipe(map(results => results));
  }
  categoriesSubList2(id_category:number) {
    return this.http.get(this.url + 'code/categoriesSubList2.php?id_category='+id_category)
      .pipe(map(results => results));
  }

  productSearch(query:any) {
    const formData = new FormData();
    formData.append('datas', query); 
    return this.http.post(`${this.url}code/productSearch.php`, formData)
      .pipe(map(results => results));
  }
  productSearchHome(query:any) {
    const formData = new FormData();
    formData.append('datas', query); 
    return this.http.post(`${this.url}code/productSearchHome.php`, formData)
      .pipe(map(results => results));
  }

  spotSearch(query:any) {
    const formData = new FormData();
    formData.append('datas', query); 
    return this.http.post(`${this.url}code/spotSearch.php`, formData)
      .pipe(map(results => results));
  }

  verifQty(query:any) {
    const formData = new FormData();
    formData.append('datas', query); 
    return this.http.post(`${this.url}code/verifQty.php`, formData)
      .pipe(map(results => results));
  }

  productDetail(id_product:number) {
    return this.http.get(this.url + 'code/productDetailAndroid.php?id_product='+id_product)
      .pipe(map(results => results));
  }

  sendPhoto(datas:any){
    const formData = new FormData();
    formData.append('datas', datas); 

    return this.http.post(`${this.url}code/sendPhoto.php`, formData);
  }


  showSpots(id_product:any) {
    return this.http.get(this.url + 'code/showSpot.php?id_product='+id_product)
      .pipe(map(results => results));
  }

  showPartners() {
    return this.http.get(this.url + 'code/showPartners.php')
      .pipe(map(results => results));
  }

  showMarkers(type:any) {
    return this.http.get(this.url + 'code/showPartners.php?type='+type)
      .pipe(map(results => results));
  }

  addressDatas(id_customer:any) {
    return this.http.get(this.url + 'code/addressDatas.php?id_customer='+id_customer)
      .pipe(map(results => results));
  }

  myAddressDatas(id_customer:any, id_address:any) {
    return this.http.get(this.url + 'code/myAddressDatas.php?id_customer='+id_customer+'&id_address='+id_address)
      .pipe(map(results => results));
  }

  addAddress(datas:any){
    const formData = new FormData();
    formData.append('datas', datas);  
    return this.http.post(`${this.url}code/addAddress.php`, formData);
  }

  updateAddress(datas:any){
    const formData = new FormData();
    formData.append('datas', datas);  
    return this.http.post(`${this.url}code/updateAddress.php`, formData);
  }

  deleteAddress(datas:any){
    const formData = new FormData();
    formData.append('datas', datas);  
    return this.http.post(`${this.url}code/deleteAddress.php`, formData);
  }

  getCarriers(datas:any){
    const formData = new FormData();
    formData.append('datas', datas);  
    return this.http.post(`${this.url}code/getCarriers.php`, formData);
  }
  getParcels(datas:any){
    const formData = new FormData();
    formData.append('datas', datas);  
    return this.http.post(`${this.url}code/mondialRelay.php`, formData);
  }


  retrieveUrlMonetico(datas:any){
    const formData = new FormData();
    formData.append('datas', datas);  
    return this.http.post(`${this.url}monetico/retrieveUrlMonetico.php`, formData);
  }

  getResponseMonetico(reference:any) {
    return this.http.get(this.url + 'monetico/responses/scanJson.php?file='+reference+'.json')
        .pipe(map(results => results));
  }

  freezeProduct(products:any){
    const formData = new FormData();
    formData.append('datas', products);  
    return this.http.post(`${this.url}monetico/freezeProducts.php`, formData);
  }

  addWebserviceCartCb(datas:any,type:boolean){
    const formData = new FormData();
    formData.append('datas', datas);  
    if(type==true){
      return this.http.post(`${this.url}webservice/addOrderCbVirtual.php`, formData);
    } else {
      return this.http.post(`${this.url}webservice/addOrderCb.php`, formData);
    }

  }

  addWebserviceCartVr(datas:any){
    const formData = new FormData();
    formData.append('datas', datas);  
    return this.http.post(`${this.url}webservice/addOrderVr.php`, formData);
  }

  addWebserviceCartFree(datas:any,type:boolean){
    const formData = new FormData();
    formData.append('datas', datas); 
    if(type==true){
      return this.http.post(`${this.url}webservice/addOrderCbVirtual.php`, formData);
    } else { 
    return this.http.post(`${this.url}webservice/addOrderFree.php`, formData);
    }
  }

  contact(email:any, phone:any, names:any, activity:any, company:any){
    const formData = new FormData();
    formData.append('email', email);  
    formData.append('names', names);
    formData.append('company', company);
    formData.append('phone',phone);
    formData.append('activity',activity);

    return this.http.post(`${this.url}contactForm.php`, formData);
  }

  sendPasswordReset(email: any){
    return this.http.post(`${this.url}retrieve.php`, {email})
      .pipe(map(results => results));
  }
 
  getPopup() {
    return this.http.get(this.url + 'code/getPopup.php')
      .pipe(map(results => results));
  }

  getUpdate() {
    return this.http.get(this.url + 'code/getUpdate.php')
      .pipe(map(results => results));
  }


}
