import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';


export interface Product {
  id: number;
  name: string;
  price: number;
  amount: number;
}


@Injectable({
  providedIn: 'root'
})
export class CartService {

  url = environment.Url;

  data: Product[] = [];


  private cart = [];
  private cartItemCount = new BehaviorSubject(0);
  private cartEbilletCount = new BehaviorSubject(0);
  private cartProductList:any = new BehaviorSubject(0);
  private cartReduction = new BehaviorSubject<number>(0);
  private cartReset = new BehaviorSubject<number>(0);
  private cartAdded = new BehaviorSubject<boolean>(false);
  private cartAddressChoose = new BehaviorSubject<number>(0);
  private cartEbillet = new BehaviorSubject<boolean>(false);
  private cartNormal = new BehaviorSubject<boolean>(false);
  private cartWeight = new BehaviorSubject<number>(0);
  private cartRelay = new BehaviorSubject<any>(null);
  private cartRelayId = new BehaviorSubject<number>(null);
  private cartDelivery = new BehaviorSubject<Object>([]);
  private btnPayment = new BehaviorSubject<boolean>(false);
  private cartIdDeliveryRelay = new BehaviorSubject<number>(null);
  private slipCode = new BehaviorSubject<any>(null);
  private stock = new BehaviorSubject<number>(1);




  constructor(private http: HttpClient) {}

  resetCart(){
    return this.cart = [];
  }
  resetCount(){
    this.cartItemCount.next(0)
  }

  getReduction(){
    return this.cartReduction;
  }

  getSlipCode(){
    return this.slipCode
  }

  getStock(){
    return this.stock;
  }

  setSlipCode(code:any){
    this.slipCode.next(code)
  }

  setStock(howmany:any){
    this.stock.next(howmany)
  }

  addReduction(reduction:number){
    this.cartReduction.next(Number(this.cartReduction.value) + Number(reduction));
  }

  getAddressChoose(){
    return this.cartAddressChoose;
  }

  selectAddress(id:number){
    this.cartAddressChoose.next(id);
    console.log('cartAddress',this.cartAddressChoose.value)
  }

  removeReduction(reduction:number){
    this.cartReduction.next(Number(this.cartReduction.value) - Number(reduction));
  }

  resetReduction(){
    this.cartReduction.next(Number(0));
  }
  getAdded(){
    return this.cartAdded;
  }

  getCartEbillet(){
    return this.cartEbillet;
  }

  getCartNormal(){
    return this.cartNormal;
  }

  getCartWeight(){
    return this.cartWeight;
  }
  /**
   * Récupération de adresse Mondial Relay
   */
  getCartRelay(){
    return this.cartRelay;
  }
  /**
   * Récupération de ID Mondial Relay
   */
  getCartRelayId(){
    return this.cartRelayId;
  }
  /**
   * Récupération Adresse de livraison
   */
  getCartDelivery(){
    return this.cartDelivery;
  }

  getBtnPayement(){
    return this.btnPayment;
  }

  getCartIdDeliveryRelay(){
    return this.cartIdDeliveryRelay;
  }


  changeAdded(value:boolean){
    this.cartAdded.next(value);
  }

  getProducts() {
    return this.cartProductList;
  }

  getCart() {
    return this.cart;
  }

  getCartItemCount() {
    return this.cartItemCount;
  }

  getCartEbilletCount() {
    return this.cartEbilletCount;
  }


  addProduct(product) {


    let added = false;
    for (let p of this.cart) {
      if (p.id_product === product.id_product) {

        p.amount += 1;
        if(p.amount>p.quantity){
          p.amount = p.quantity
        }
        added = true;

        break;
      } 
    }
    if (!added) {
      product.amount = 1;
      this.cart.push(product);

    }



    if(product.ebillet===1){
      this.cartEbillet.next(true);
//console.log('this.cartEbillet add',this.cartEbillet.value)
    }

    if(product.ebillet!==1){
      this.cartNormal.next(true);
   //console.log('this.cartNormal add',this.cartNormal.value)
    }

    this.cartWeight.next(this.cartWeight.value + Number(product.weight));
    this.cartItemCount.next(this.cartItemCount.value + 1);
    this.cartProductList = this.cart;

    
 

  }

  decreaseProduct(product) {
    for (let [index, p] of this.cart.entries()) {
      if (p.id_product === product.id_product) {
        p.amount -= 1;
        const ebil = p.ebillet;
     
        if (p.amount == 0) {

          if(p.ebillet==1){
            this.cartEbillet.next(false);
            //console.log('decrease removal ebillet',this.cartEbillet.value)
          }
          if(p.ebillet!==1){
            this.cartNormal.next(false);
            //console.log('decrease removal normal',this.cartNormal.value)
          }
            this.cart.splice(index, 1);

        }
        
      /*  if(product.ebillet==1){
          this.cartEbillet.next(true);
    
        }
        if(product.ebillet!==1){
          this.cartNormal.next(true);
        }*/
      }
    }
    //console.log('product.ebillet Dec ',product.ebillet)

    this.cartWeight.next(this.cartWeight.value - Number(product.weight));
    this.cartItemCount.next(this.cartItemCount.value - 1);
    this.cartProductList = this.cart;

    

  //console.log('this.cartNormal',this.cartNormal)
  //console.log('this.cartEbillet',this.cartEbillet)

  }

  removeProduct(product:any) {
    //console.table(product)
    for (let [index, p] of this.cart.entries()) {
      if (p.id_product === product.id_product) {

 
        this.cartWeight.next(this.cartWeight.value - Number(product.weight));
        this.cartItemCount.next(this.cartItemCount.value - p.amount);
        this.cart.splice(index, 1);
        this.cartProductList = this.cart;

        if(product.ebillet==1){
          this.cartEbillet.next(false);
          //console.log('remove ebillet')

        }
        if(product.ebillet!==1){
          this.cartNormal.next(false);
          //console.log('remove normal')

        }

       

      }
    }
  }

  addRelay(relay:any){
    let AddressRelay = relay[4] + '<br>' + relay[1] + ', ' + relay[2] + ' ' + relay[3];
    this.cartRelay.next(AddressRelay);
    this.cartRelayId.next(relay[0]);
    this.cartIdDeliveryRelay.next(relay[5]);
    this.btnPayment.next(true);
    console.log('btn payement TRUE from addRelay');

    this.addAddress(relay).subscribe(
      async (data: any) => {
      //  console.log('data after add address relay',data)
      //  console.log('lastid',data.lastId)
        
        this.cartAddressChoose.next(data.lastId)

      })
    
  }

  removeRelay(){   
    this.cartRelay.next(null);
    this.cartRelayId.next(null);
    this.btnPayment.next(false);
    //console.log('btn pyement FLASE from removeRelay');
    this.cartIdDeliveryRelay.next(null);
  }

  addDelivery(delivery:any){
    this.cartDelivery.next(delivery);
    this.btnPayment.next(true);
    //console.log('btn pyement TRUE from addDeivery');
    //console.log('return btn ',this.getBtnPayement())
  }

  resetDelivery(){
    this.cartDelivery.next('');
  }

  resetProductType(){
    this.cartEbillet.next(false);
    this.cartNormal.next(false);
  }

  addAddress(relay:any){

    let postData = {
      "alias": "Point Mondial Relay : FR-" + relay[0],
      "firstname": localStorage.getItem('firstname'),
      "lastname": localStorage.getItem('lastname'),
      "address1": relay[1],   
      "address2": '',
      "postcode": relay[2],
      "city": relay[3],
      "phone": localStorage.getItem('phone'),
      "company": relay[4],
      "id_customer" : localStorage.getItem('id_customer'),
    }
    
    const formData = new FormData();
    formData.append('datas', JSON.stringify(postData));  
    return this.http.post(`${this.url}code/addAddress.php`, formData);



  }

  removeAddress(){

    let postData = {   
      "id_address": this.cartIdDeliveryRelay.value,
      "id_customer" : localStorage.getItem('id_customer'),
    }
    
    const formData = new FormData();
    formData.append('datas', JSON.stringify(postData));  
    return this.http.post(`${this.url}code/deleteAddress.php`, formData);
  }


  removeAddressPointRelay(){
    let postData = {   
      "id_customer" : localStorage.getItem('id_customer'),
    }
    const formData = new FormData();
    formData.append('datas', JSON.stringify(postData));  
   // console.log('remove point')
    return this.http.post(`${this.url}code/deleteAddressPointRelay.php`, formData);
    

  }

}