
export const environment = {
  production: true,
  Url: 'https://www.intercea.fr/restApi/',
  Url2: 'https://www.intercea.fr/',
  atpUrl: 'https://www.atpnancy.fr/restApi/',
  atpUrl2: 'https://www.atpnancy.fr/',
  // stripePublicKey: 'pk_test_51P8lKiRxvOQLl51C6nDFRArv3gr9jJrjvzkDMWrHsbUwIruR0VBvOLSf5CFrBQBt7vWJvS67ATbOxLAkssh0o6ZR00zCWIXP7H',
};
export const SERVER_URL = 'https://www.intercea.fr/restApi/';

/*
export const environment = {
  production: true,
  Url:'https://www.intercea.ovh/restApi/',
  Url2:'https://www.intercea.ovh/',
};

export const SERVER_URL = 'https://www.intercea.ovh/restApi/';
*/