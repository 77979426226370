<ion-content fullscreen>
  <ion-grid>
    <ion-row>
      <ion-col size="3"><ion-img src="assets/intercea_header.webp"></ion-img></ion-col>
      <ion-col size="9">
        <div class="ion-text-end">
          <ion-button (click)="close()" fill="clear" color="dark">
            <ion-icon name="close" slot="start"></ion-icon>
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>



  <div class="ion-padding">

    <ion-list>
      <ion-item *ngFor="let p of cart" class="ion-text-wrap ion-no-padding">
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col size="2" class="ion-align-self-center">
              <ion-button color="medium" fill="clear" (click)="decreaseCartItem(p)">
                <ion-icon name="remove-circle" slot="icon-only"></ion-icon>
              </ion-button>
            </ion-col>

            <ion-col size="1" class="ion-align-self-center">
              <span class="number">{{ p.amount }}</span>
            </ion-col>

            <ion-col size="2" class="ion-align-self-center">
              <ion-button color="medium" fill="clear" (click)="increaseCartItem(p)">
                <ion-icon name="add-circle" slot="icon-only"></ion-icon>
              </ion-button>
            </ion-col>

            <ion-col size="2" offset="5">
              <ion-button color="medium" fill="clear" (click)="removeCartItem(p)">
                <ion-icon name="close-circle" slot="icon-only"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="8">
              <b style="font-size: 15px;">{{ p.name }}</b>
            </ion-col>
            <ion-col size="4" class="ion-text-end" style="font-size: 15px;">
              {{ p.amount * p.price | currency:'EUR' }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
      <ion-item class="ion-text-wrap ion-no-padding">
        <ion-grid>
          <ion-row>
            <ion-col size="8">
              Total:
            </ion-col>
            <ion-col size="4" class="ion-text-end" style="font-weight: bold;">
              {{ getTotal() | currency:'EUR' }}
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>

    <ion-button class="action-button" expand="block" fill="solid" color="primary" (click)="checkout()" *ngIf="getTotal()>0" type="button">
      <span>Commander</span>
    </ion-button>
  </div>

</ion-content>